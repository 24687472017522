import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../../components/Footer';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../firebase';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ForgotPassword() {

  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [success, setSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if(data.get('email')!=''){
      sendPasswordResetEmail(auth, data.get('email'))
  .then((res) => {
    // Sent email
    setAlert(false);
    setSuccessContent("Kindly check your email to reset password");
    setSuccess(true);
    // const user = userCredential.user;
    // localStorage.setItem("accessToken", user.accessToken);
    // localStorage.setItem("role", user.uid);
    // if (user.uid == "jK8shDiB1pcUcZYzj4XscxIDcLB3"){
    //   navigate('/products');
    // }else{
    //   navigate('/products-sales');
    // }
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(
      errorCode
    );
    if(errorCode == "auth/invalid-credential"){
      setSuccess(false);
      setAlertContent("Invalid email or password");
      setAlert(true);
    }else if (errorCode == "auth/too-many-requests"){
      setSuccess(false);
      setAlertContent("Too many requests, please try again after 5 minutes");
      setAlert(true);
    }
    else{
      setSuccess(false);
      setAlertContent(errorMessage);
      setAlert(true);
    }
    // ..
  });
}else{
  setSuccess(false);
  setAlertContent("Missing email or password");
  setAlert(true);
}
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {alert ? <Alert severity='error'>{alertContent}</Alert> : <></> }
          {success ? <Alert severity='success'>{successContent}</Alert> : <></> }
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography component="h3" variant="h6">
            B2B Global Renaissance Collections
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Password Email
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
              {/* <Grid item>
                <Link href="signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}