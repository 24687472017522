import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "users"

const users_api = axios.create({
  baseURL: `${base_url()}/${services}`,
  timeout: 30000,
});

export const get_one_user = async (id) => {
    try {
      const response = await users_api.get(`/list?id=${id}`, options());
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
};

export const get_users_by_role = async (role) => {
  try {
    const response = await users_api.post(`/list/role`, role, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const add_one_user = async (user_details) => {
  try {
    const response = await users_api.post(`/add`, user_details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const update_user_product = async (user_item) => {
  try {
    const response = await users_api.put(`/update-product`, user_item , options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const delete_all_user_product = async (id, user_item) => {
  try {
    const response = await users_api.delete(`/delete-product?id=${id}`, {
      headers : {'Authorization': 'Bearer iloveshenyun&4cPu0PCUkN2C4u@xOs6%XUy2K@dqWuq',
        'Content-Type': 'application/json',
    },
      data: user_item
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
