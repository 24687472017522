import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "products"

const products_api = axios.create({
    baseURL: `${base_url()}/${services}`,
    timeout: 30000,
});

export const get_all_products = async () => {
    try {
      const response = await products_api.get(`/list`, options());
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
};

export const get_all_products_with_videos = async () => {
  try {
    const response = await products_api.get(`/list?showVideo=true`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};



export const get_distributor_products = async (role,uid) => {
  try {
    const response = await products_api.get(`/list?role=${role}&uid=${uid}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_limited_products = async (limit) => {
  try {
    const response = await products_api.get(`/list?limit=${limit}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_product_by_id = async (id) => {
  try {
    const response = await products_api.get(`/list?id=${id}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const update_product = async (product_item) => {
  try {
    const response = await products_api.put(`/update`, product_item , options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const add_one_product = async (product_item) => {
  try {
    const response = await products_api.post(`/import`, product_item , options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
