import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import Star from '@mui/icons-material/Star';
import {countries} from './CountrySelector';
import Diversity3Icon from '@mui/icons-material/Diversity3';

export default function DistributorCard({ title, preorder = false, image, country, id, retailprice, variantcompareprice, currentstock, samplestock, onClick}) {

  const countryArray = countries.filter((eachCountry) => eachCountry.code.includes(country));

  function handleClick() {
    onClick(id);
  }

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        bgcolor: 'neutral.softBg',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        '&:hover': {
          boxShadow: 'lg',
          borderColor: 'var(--joy-palette-neutral-outlinedDisabledBorder)',
        },
      }}
      onClick={handleClick}
    >
      <CardOverflow
        sx={{
          mr: { xs: 'var(--CardOverflow-offset)', sm: 0 },
          mb: { xs: 0, sm: 'var(--CardOverflow-offset)' },
          '--AspectRatio-radius': {
            xs: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0',
            sm: 'calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0 calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px))',
          },
        }}
      >
        <AspectRatio
          ratio="1"
          flex
          sx={{
            minWidth: { sm: 120, md: 160 },
            '--AspectRatio-maxHeight': { xs: '160px', sm: '9999px' },
          }}
        >
          <img alt="" src={image} />
          <Stack
            alignItems="center"
            direction="row"
            sx={{ position: 'absolute', top: 0, width: '100%', p: 1 }}
          >
            {preorder && (
              <Chip
                variant="soft"
                color="success"
                startDecorator={<WorkspacePremiumRoundedIcon />}
                size="md"
              >
                Pre-order
              </Chip>
            )}
            <IconButton
              variant="plain"
              size="sm"
              color={currentstock>0 ? 'success' :'danger' }
              // onClick={() => setIsLiked((prev) => !prev)}
              sx={{
                display: { xs: 'flex', sm: 'none' },
                ml: 'auto',
                borderRadius: '50%',
                zIndex: '20',
              }}
            >
              <CircleRoundedIcon />
            </IconButton>
          </Stack>
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <div>
            <Typography level="body-sm">{id}</Typography>
            <Typography level="title-md">
              <Link
                overlay
                underline="none"
                href="#interactive-card"
                sx={{ color: 'text.primary' }}
              >
                {title}
              </Link>
            </Typography>
          </div>
          <IconButton
            variant="plain"
            size="sm"
            color={currentstock>0 ? 'success' : 'danger'}
            // onClick={() => setIsLiked((prev) => !prev)}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              borderRadius: '50%',
            }}
          >
            <CircleRoundedIcon />
          </IconButton>
        </Stack>
        <Stack
          spacing="0.25rem 1rem"
          direction="row"
          useFlexGap
          flexWrap="wrap"
          sx={{ my: 0.25 }}
        >
          <Typography level="body-xs" startDecorator={<FmdGoodRoundedIcon />}>
            Made in {countryArray[0]?.label}
          </Typography>
          {/* <Typography level="body-xs" startDecorator={<BadgeRoundedIcon />}>
            {id}
          </Typography> */}
          <Typography level="body-xs" startDecorator={<Diversity3Icon />}>
            RM{((retailprice - variantcompareprice * 5) / (variantcompareprice * 5) * 100)>=60?
    (retailprice*20/100).toFixed(2) + " (20%)":(retailprice*10/100).toFixed(2) + " (10%)"}
          </Typography>
          <Typography level="body-xs" startDecorator={<PaidRoundedIcon />}>
            RM{retailprice-variantcompareprice*5 + " (" + ((retailprice-variantcompareprice*5)/(variantcompareprice*5)*100).toFixed(2) + "%)"}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ mt: 'auto' }}>
          <Typography
            level="title-sm"
            // startDecorator={
            //   <React.Fragment>
            //     <Star sx={{ color: 'warning.400' }} />
            //     <Star sx={{ color: 'warning.400' }} />
            //     <Star sx={{ color: 'warning.400' }} />
            //     <Star sx={{ color: 'warning.400' }} />
            //     <Star sx={{ color: 'warning.200' }} />
            //   </React.Fragment>
            // }
            // sx={{ display: 'flex', gap: 1 }}
          >
            {currentstock? currentstock==1 ? currentstock + " stock left" : currentstock + " stocks left" :""}
            {samplestock? currentstock>=1 ? " & 1 sample": "1 sample" : ""}
          </Typography>
          <Typography level="title-lg" sx={{ flexGrow: 1, textAlign: 'right' }}>
            <strong>RM{(parseFloat(variantcompareprice)*5).toFixed(2)}</strong> <strike>RM{parseFloat(retailprice).toFixed(2)}</strike> <Typography level="body-md">total</Typography>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
