import { useEffect, useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import * as StocksServices from '../../services/StocksServices';
import NavBar from '../../components/NavBar';
import Typography from '@mui/joy/Typography';
import LinearProgress from '@mui/joy/LinearProgress';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Grid from '@mui/joy/Grid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import * as TimeUtils from '../../utils/TimeUtils';
import * as PriceUtils from '../../utils/PriceUtils';
import TabsSegmentedControls from '../../components/SegmentedControl';


export default function StocksDashboard() {

  const [accessToken, setAccessToken]= useState(localStorage.getItem("accessToken"));
  const [username, setUsername]= useState(localStorage.getItem("name"));
  const [email, setEmail]= useState(localStorage.getItem("email"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);


  // API CALL
  const read_all_products_sold = async () => {
    try {
      setLoading(true);
      if (role == "distributor" || role == "superadmin"  || role == "admin"){
        let res = await StocksServices.get_all_products_sold_by_distributorid(uid);
        setProductsData(res.data);
      }else{
        setProductsData([]);
      }
      setLoading(false);
    } catch (e) {

    }
  };

  useEffect(() => {
    read_all_products_sold();
  },[])

  const StocksCard = ({image,productid,productname,quantity,productprice,distributorprice,discounttype,invoiceid,invoicedate,salesperson,distributor}) => {
    return (
      <Card sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg' }}>
        <CardOverflow>
          <AspectRatio sx={{ minWidth: 200 }}>
            <img
              src={image}
              loading="lazy"
              alt=""
            />
          </AspectRatio>
        </CardOverflow>
        <CardContent>
          <Typography level="body-xs">{productid}</Typography>
          <Link
            fontWeight="md"
            color="neutral"
            textColor="text.primary"
            overlay
            underline='none'
            // endDecorator={<ArrowOutwardIcon />}
          >
            {productname} x {quantity}
          </Link>

          <Typography
            level="title-lg"
            sx={{ mt: 1, fontWeight: 'xl' }}
          >
            Sales
          </Typography>

          <Stack spacing={1} direction="row" sx={{ mb: 2 }}>{salesperson?.map((sales)=><Chip component="span" size="sm" variant="soft" color="success">
                {sales?.firstname + " " + sales?.lastname}
              </Chip>)}</Stack>

          <Typography
            level="title-md"
            sx={{ mt: 1, fontWeight: 'xl' }}
          >
            Distributed by {distributor?.map((each) => 
              <Typography
              level="title-md"
              sx={{ mt: 1, fontWeight: 'xl' }}
            >{each?.firstname + " " + each?.lastname}</Typography>
              )}
          </Typography>

          

          <Typography level="body-sm">
            Invoice ID: {invoiceid}
          </Typography>
          <Typography level="body-sm">
            (Sold on {TimeUtils.formatTimeToLocaleString(invoicedate)})
          </Typography>
          <Typography level="body-sm">
            Total Sold Price RM{(productprice*quantity).toFixed(2)}
          </Typography>
          <Typography level="body-sm">
            Total Product Cost RM{(distributorprice*quantity).toFixed(2)}
          </Typography>
          <Typography level="body-sm">
            Total Discount RM{discounttype=="lifetime" ? (productprice*0.05*quantity).toFixed(2) : (productprice*0*quantity).toFixed(2)}
          </Typography>
          <Typography level="body-sm">
            Total Commission RM{(productprice-distributorprice)/distributorprice*100 >= 60 ? (productprice*quantity*0.2).toFixed(2) : (productprice*quantity*0.1).toFixed(2)}
          </Typography>
        </CardContent>
        <CardOverflow>
          <Button variant="solid" color="danger" size="lg" disabled>
            Sold with gross profit RM{(PriceUtils.calculateGrossProfit(discounttype,productprice,distributorprice)*quantity).toFixed(2)}
          </Button>
        </CardOverflow>
      </Card>
    );
  }

  if (loading) {
    return (<CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}

        >
          <LinearProgress/>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
    );
    } else if (!loading && accessToken && !error && (role == "superadmin" || role == "admin" || role=="sales" || role=="distributor")){

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: '100%' },
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'background.surface',
            px: { xs: 2, md: 4 },
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent:"center",
            }}
          >
            <Typography level="h2" component="h1">
              Goods Sold
            </Typography> 
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent:"center",
            }}
          >
          {/* <TabsSegmentedControls tabs={["test","test2"]}/> */}
          </Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ flexGrow: 1 }}
            justifyContent="center"
            alignItems="center"
          >
          {productsData?.map((product)=>
           <Grid xs="auto"><StocksCard
            image={product?.distributedproduct?.productdetails?.productimages}
            productid = {product?.distributedproduct?.id}
            productname = {product?.distributedproduct?.productdetails?.productname}
            productprice = {product?.distributedproduct?.productdetails?.retailprice}
            quantity = {product?.distributedproduct?.quantity}
            discounttype = {product?.invoice?.discounttype}
            distributorprice = {product?.distributedproduct?.productdetails?.variantcompareprice*5}
            invoiceid = {product?.invoice?.id}
            invoicedate = {product?.invoice?.createdAt}
            salesperson = {product?.invoice?.salesperson}
            distributor = {product?.distributedproduct?.distributor}
            insurancecost = {product?.insurancetype}
          /></Grid>
          )}</Grid>
          </Stack>
        {/* <Pagination /> */}
      </Box>
    </CssVarsProvider>
  );
}else{
  return(
    <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <Stack
            sx={{
              backgroundColor: 'background.surface',
              px: { xs: 2, md: 4 },
              py: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography>You are not authorised to view this page. Kindly sign in first.</Typography>
          </Stack>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
  );
}

}