import { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CssVarsProvider } from '@mui/joy/styles';
import { NumericFormat } from 'react-number-format';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import * as StocksServices from '../../services/StocksServices';
import * as UsersServices from '../../services/UsersServices';
import * as PayoutServices from '../../services/PayoutServices';
import NavBar from '../../components/NavBar';
import Typography from '@mui/joy/Typography';
import LinearProgress from '@mui/joy/LinearProgress';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Grid from '@mui/joy/Grid';
import * as TimeUtils from '../../utils/TimeUtils';
import * as PriceUtils from '../../utils/PriceUtils';
import TabsSegmentedControls from '../../components/SegmentedControl';
import UserCard from '../../components/UserCard';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';

import Checkbox from '@mui/joy/Checkbox';

var selectedPayout = [];

//save selected checkbpx state here

export default function StocksDashboard() {

  const [accessToken, setAccessToken]= useState(localStorage.getItem("accessToken"));
  const [username, setUsername]= useState(localStorage.getItem("name"));
  const [email, setEmail]= useState(localStorage.getItem("email"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [salespeople, setSalespeople] = useState([]);
  const [segmentedTab, setSegmentedTab] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [selectedSales, setSelectedSales] = useState([]);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  var paymenttype = "cash";

  const inputRef = useRef(null);

  const productRef = useRef([]);

  const NumericFormatAdapter = forwardRef(
    function NumericFormatAdapter(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          valueIsNumericString
          prefix="RM"
        />
      );
    },
  );
  
  NumericFormatAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

    const handleSelectedTab = (data) =>{
        if (data == 1){
            setSegmentedTab(1);
            setProductsData([]);
        } else {
            setSegmentedTab(0);
            read_all_products_sold();
        }
    } 

    const handleOnPaymentChange = (event) => {
        paymenttype=event.target.id;
      }

  // API CALL
  const read_all_products_sold = async (id) => {
    try {
        setProductsData([]);
        setSelectedSales([]);
        setTotalCommission(0);
      setLoading(true);
      if (id != null && (role == "superadmin"  || role == "admin")){
        let res = await StocksServices.get_all_products_sold_by_salesid(id);
        setProductsData(res.data);
        let total = 0.0;
        res.data != null && res.data != [] && res.data.length > 0 ? res.data.map ((product) =>
            total += parseFloat(PriceUtils.splitCommissionEqually(product?.invoice?.b2border,product?.soldproduct?.productdetails?.retailprice,product?.soldproduct?.productdetails?.variantcompareprice*5,product?.soldproduct?.quantity,product?.invoice?.salesperson?.length)))
        : total = 0.0;  
        console.log(total.toFixed(2));
        let salesperson = salespeople.filter((sales)=> sales.id.includes(id));
        console.log(salesperson);
        setSelectedSales(salesperson[0]);
        setTotalCommission(total);
      }else{
        setProductsData([]);
      }
      setLoading(false);
    } catch (e) {

    }
  };

  // API CALL
  const get_all_sales = async () => {
    try {
      let data = {"role":["superadmin","admin","distributor","sales"]}
      let res = await UsersServices.get_users_by_role(data);
      setSalespeople(res.data.users);
    } catch (e) {
      console.log(e);
    }
  };

  const add_one_payout = async (data) => {
    try{
        let res = await PayoutServices.add_one_payout(data);
        if (res.data?.success == true){
            alert("You have added an payout successfully")
        }
    }catch (e){
        console.log(e);
    }

  }

    const handleSelectedPerson = (data) => {
        console.log(data);
        //read_all_products_sold(newValue);
    }

  useEffect(() => {
    get_all_sales();
  },[])

  const PaymentModal = () =>{
    return (
      <Modal open={paymentModalOpen} 
      style = {{
        overflow:'scroll'
      }}
      onClose={()=>setPaymentModalOpen(false)}>
        <ModalDialog>
        <DialogTitle>Create Payout</DialogTitle>
          <DialogContent>Total Commission: RM{totalCommission.toFixed(2)}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if(paymenttype==""){
                paymenttype="cash"
              }
              const formElements = event.currentTarget.elements;
                const data = {
                    id: uid,
                    adminname: username,
                    adminemail: email,
                    paymenttype : paymenttype,
                    remarks: formElements.remarks.value,
                    estimatedCommission: totalCommission,
                    transferredAmount:  formElements.transferredamount.value,
                    finalAmount: formElements.finalamount.value,
                    createdAt: Date.now(),
                    selectedPayout: productRef
                  };
                  // alert(JSON.stringify(data, null, 2));
              add_one_payout(JSON.stringify(data, null, 2));
              setPaymentModalOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Preferred Payment Type*</FormLabel>
                <Select name="paymenttype" onChange={handleOnPaymentChange} defaultValue="cash" required>
                  <Option value="cash" id="cash">Cash</Option>
                  <Option value="card" id="card">Card</Option>
                  <Option value="ewallet" id="ewallet">E-Wallet</Option>
                  <Option value="banktransfer" id="banktransfer">Bank Transfer</Option>
                  <Option value="exchange" id="exchange">Exchange for Product</Option>
                  <Option value="directdeduct" id="directdeduct">Deduct Commission When Transfer</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{"Final Amount"}</FormLabel>
                <Input type="number"
        slotProps={{
          input: {
            ref: inputRef,
            min: 0.01,
            step: 0.01,
          },}} startDecorator={<Typography>RM</Typography>} name="finalamount" />             
              </FormControl>
              <FormControl>
                <FormLabel>{"Transferred Amount"}</FormLabel>
                <Input type="number"
        slotProps={{
          input: {
            ref: inputRef,
            min: 0.01,
            step: 0.01,
          },}} startDecorator={<Typography>RM</Typography>} name="transferredamount" />             
              </FormControl>
              <FormControl>
                <FormLabel>{"Remarks (Optional)"}</FormLabel>
                <Input name="remarks" />
              </FormControl>
              <Button type="submit" onClick={onsubmit}>Confirm Order</Button>
            </Stack>
          </form> </DialogContent>
          <ModalClose />
        </ModalDialog>
      </Modal>
    );
  }

  

 function SelectBasic() {

    var selected_value = ""

    const handleSalesChange = (event, newValue) => {
        selected_value = newValue;
    }

    const handleGetInvoiceInfo = () => {
        if (selected_value != null && selected_value != "") {
            read_all_products_sold(selected_value)
        }
    }

    function handlePaymentButtonClick(){
        setPaymentModalOpen(true);
      }
    
    return (
        <Stack>
      <Stack direction="row" gap={2}>
      <Select placeholder="Select a sales"
          onChange={handleSalesChange}
          required>
        {salespeople.map((sales) => <Option defaultValue={selected_value} value={sales?.id}>{sales?.firstname + " " + sales?.lastname + " (" + sales?.role + ")"}</Option>)}   
      </Select>
      <Button onClick={()=>handleGetInvoiceInfo()}>Search</Button>
      </Stack>
      
       {(selectedSales?.firstname != null) ? <Stack direction="row" gap={2}><Typography>{selectedSales?.firstname + " " + selectedSales?.lastname + " - " + selectedSales?.role + " (Total Commission: RM" + totalCommission.toFixed(2) + ")"}</Typography>
       <Button Button fontWeight="md" color="success" onClick={handlePaymentButtonClick}>Payout</Button><PaymentModal/></Stack> : <></>}
       
       </Stack>
    );
  }



  const StocksCard = ({b2b,image,productid,productname,quantity,productprice,distributorprice,discounttype,invoiceid,invoicedate,salesperson,distributor}) => {
    
    function handleCheckboxChange(event) {
        // checked is true:
        if (event.target.checked){
            const data = {
                invoiceid: invoiceid
                
            }
            selectedPayout.push(data);
            console.log(selectedPayout)
        }
    }
    
    return (
      <Card sx={{ width: 320, maxWidth: '100%', boxShadow: 'lg' }}>
        <CardOverflow>
          <AspectRatio sx={{ minWidth: 200 }}>
            <img
              src={image}
              loading="lazy"
              alt=""
            />
          </AspectRatio>
        </CardOverflow>
        <CardContent>
          <Typography level="body-xs">{productid}</Typography>
          <Link
            fontWeight="md"
            color="neutral"
            textColor="text.primary"
            overlay
            underline='none'
            endDecorator={<Checkbox onChange={handleCheckboxChange}/>}
          >
            {productname} x {quantity}
          </Link>

          <Typography
            level="title-lg"
            sx={{ mt: 1, fontWeight: 'xl' }}
          >
            Sales
          </Typography>

          <Stack spacing={1} direction="row" sx={{ mb: 2 }}>{salesperson?.map((sales)=><Chip component="span" size="sm" variant="soft" color="success">
                {sales?.firstname + " " + sales?.lastname}
              </Chip>)}</Stack>        

          <Typography level="body-sm">
            {b2b ? "B2B " : ""}Invoice ID: {invoiceid}
          </Typography>
          <Typography level="body-sm">
            (Sold on {TimeUtils.formatTimeToLocaleString(invoicedate)})
          </Typography>
          <Typography level="body-sm">
            Total Sold Price RM{(distributorprice*quantity).toFixed(2)}
          </Typography>
          <Typography level="body-sm">
            Total Commission RM{PriceUtils.calculateCommission(b2b,productprice,distributorprice,quantity)}
          </Typography>
        </CardContent>
        <CardOverflow>
          <Button variant="solid" color="danger" size="lg" disabled>
            Sold with commission RM{PriceUtils.splitCommissionEqually(b2b,productprice,distributorprice,quantity,salesperson.length)}
          </Button>
        </CardOverflow>
      </Card>
    );
  }

  if (loading) {
    return (<CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}

        >
          <LinearProgress/>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
    );
    } else if (!loading && accessToken && !error && (role == "superadmin" || role == "admin" || role=="sales" || role=="distributor")){

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: '100%' },
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'background.surface',
            px: { xs: 2, md: 4 },
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent:"center",
            }}
          >
            <Typography level="h2" component="h1">
              Payout
            </Typography> 
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent:"center",
            }}
          >
          <TabsSegmentedControls tabs={["Sales","Distributors"]} selected_value={handleSelectedTab}/>
          {segmentedTab == 0 ? <SelectBasic/> : <></>}
          </Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ flexGrow: 1 }}
            justifyContent="center"
            alignItems="center"
          >
            
          {productsData?.map((product)=>
           <Grid xs="auto"><StocksCard
            b2b={product?.invoice?.b2border}
            image={product?.soldproduct?.productdetails?.productimages}
            productid = {product?.soldproduct?.id}
            productname = {product?.soldproduct?.productdetails?.productname}
            productprice = {product?.soldproduct?.productdetails?.retailprice}
            quantity = {product?.soldproduct?.quantity}
            discounttype = {product?.invoice?.discounttype}
            distributorprice = {product?.soldproduct?.productdetails?.variantcompareprice*5}
            invoiceid = {product?.invoice?.id}
            invoicedate = {product?.invoice?.createdAt}
            salesperson = {product?.invoice?.salesperson}
            distributor = {product?.soldproduct?.distributor}
          /></Grid>
          )}</Grid>
          </Stack>
        {/* <Pagination /> */}
      </Box>
    </CssVarsProvider>
  );
}else{
  return(
    <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <Stack
            sx={{
              backgroundColor: 'background.surface',
              px: { xs: 2, md: 4 },
              py: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography>You are not authorised to view this page. Kindly sign in first.</Typography>
          </Stack>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                'url("https://www.shenyuncollections.com/cdn/shop/files/ABOUT-US-SY.jpg?v=1675921248&width=3200")',
            }}
          />
        </Box>
      </CssVarsProvider>
  );
}

}