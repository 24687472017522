import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../../components/Footer';
import { PrimarySearchAppBar } from '../../components/Header';
import { DataGrid, GridToolbar, GridRowModes, GridActionsCellItem, GridRowEditStopReasons, } from '@mui/x-data-grid';
import * as ProductsServices from '../../services/ProductsServices'
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {countries} from '../../components/CountrySelector';

const defaultTheme = createTheme();

export default function ColumnGroupingTable() {

  const [productsData, setProductsData] = useState({});
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));

  const countryArray = countries;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setProductsData(productsData.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = productsData.find((row) => row.id === id);
    if (editedRow.isNew) {
      setProductsData(productsData.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setProductsData(productsData.map((row) => (row.id === newRow.id ? updatedRow : row)));
    update_product(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  
const columns = [
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }
  
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  },
  { field: 'id', headerName: 'ID', width: 170 , valueFormatter: (value) => `${value? value: ""}`},
  { field: 'productimages', headerName: 'Image', width: 150, editable:true,
renderCell: (params) => <Avatar sx={{ width: 100, height: 100 }} variant="square" alt="Shen Yun Collections" src={params?.value} />},
{ field: 'productname', headerName: 'Name', width: 350 , editable:true, valueFormatter: (value) => `${value? value: ""}`},
{ field: 'country', headerName: 'Country', width: 170 , editable:true, valueFormatter: (value) => `${value ? value: ""}`},
{ field: 'productdescription', headerName: 'Description', width: 170 , editable:true, valueFormatter: (value) =>`${value? value: ""}`},
{ field: 'variantcompareprice', headerName: 'Buy Price', editable:true, type:"number", width: 100, valueFormatter: (value) => `US$ ${value?.toFixed(2)}`},
{ field: 'retailprice', headerName: 'Sell Price', editable:true, type:"number", width: 100, valueFormatter: (value) => `RM ${value?.toFixed(2)}`},
{
  field: 'profit',
  headerName: 'Estimated Profit',
  sortable: true,
  width: 160,
  valueGetter: (value, row) => (row?.retailprice - row?.variantcompareprice * 5),
  valueFormatter: (value) => `RM ${value?.toFixed(2)}`
},
{
  field: 'profit_percent',
  headerName: 'Estimated Profit (%)',
  sortable: true,
  width: 160,
  valueGetter: (value, row) => (row?.retailprice - row?.variantcompareprice * 5) / (row?.variantcompareprice * 5) * 100,
  valueFormatter: (value) => `${value?.toFixed(0)}%`
},
{
  field: 'commission',
  headerName: 'Commission',
  sortable: true,
  width: 160,
  valueGetter: (value, row) => (
    ((row?.retailprice - row?.variantcompareprice * 5) / (row?.variantcompareprice * 5) * 100)>=60?
    (row?.retailprice*20/100):(row?.retailprice*10/100))
    ,
  valueFormatter: (value) => `RM${value?.toFixed(2)}`
},
{
  field: 'commission_percent',
  headerName: 'Commission (%)',
  sortable: true,
  width: 160,
  valueGetter: (value, row) => (
    ((row?.retailprice - row?.variantcompareprice * 5) / (row?.variantcompareprice * 5) * 100)>=60?20:10),
  valueFormatter: (value) => `${value?.toFixed(0)}%`
},
{ field: 'SKU', headerName: 'SKU', width: 150, editable:true, },
{ field: 'video', headerName: 'Video', width: 150, editable:true, },
{ field: 'MOQ', headerName: 'MOQ', width: 150, editable:true, type:"number" },
{ field: 'initialstock', headerName: 'Initial Stock', width: 150, editable:true, type:"number"  },
{ field: 'currentstock', headerName: 'Current Stock', width: 150, editable:true, type:"number" },
{ field: 'samplestock', headerName: 'Sample Only', width: 150, editable:true, type:"number"  },
{ field: 'preorder', headerName: 'Pre-order', width: 150, editable:true, type:"boolean"},

];

const verify_user = () => {
  setAccessToken(localStorage.getItem("accessToken"));
  setRole(localStorage.getItem("role"));
}

  // API CALL
  const fetch_products = async () => {
    try {
      setLoading(true);
      verify_user();
      let res = await ProductsServices.get_all_products();
      setProductsData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  // API CALL
  const update_product = async (productDetails) => {
    try {
      let res = await ProductsServices.update_product(productDetails);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    fetch_products();
  },[])

  if (loading) {
    return (
      <ThemeProvider theme={defaultTheme}>
            <PrimarySearchAppBar/>
        <Container component="main" sx={{p:2}}>
            <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
        <Copyright sx={{ mt: 5 }} />
        </Container>
        </ThemeProvider>
    )}

  else if (!loading && accessToken && !error && role == "superadmin"){

    return(
        <ThemeProvider theme={defaultTheme}>
            <PrimarySearchAppBar/>
        <Container component="main" sx={{p:2}}>
            <CssBaseline />
        <Paper sx={{ width: '100%'}}>
        <Button onClick={handleOpen} href="/create-product">Add item</Button>
        <DataGrid
            loading={loading}
            rowHeight={100}
            rows={productsData}
            columns={columns}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5, 10]}
            disableColumnFilter
            disableDensitySelector
            slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
            slotProps={{
            toolbar: {
                showQuickFilter: true,
            },
            }}
        />
        </Paper>
        <Copyright sx={{ mt: 5 }} />
        </Container>
        </ThemeProvider>
    );
    }else{
    return(
        <ThemeProvider theme={defaultTheme}>
            <PrimarySearchAppBar/>
        <Container component="main" sx={{p:2}}>
            <CssBaseline />
        <Paper sx={{ width: '100%'}}>
         <p> You are not authorised to view this page</p>
        </Paper>
        <Copyright sx={{ mt: 5 }} />
        </Container>
        </ThemeProvider>
    );
  }
}