import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "customers"

const users_api = axios.create({
  baseURL: `${base_url()}/${services}`,
  timeout: 30000,
});

export const get_one_customer = async (id) => {
    try {
      const response = await users_api.get(`/list?id=${id}`, options());
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
};

export const get_one_customer_by_email = async (email) => {
    try {
      const response = await users_api.get(`/email/list?email=${email}`, options());
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
};

export const add_one_customer = async (user_details) => {
  try {
    const response = await users_api.post(`/add`, user_details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};