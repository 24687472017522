export const base_url = () => {

if (window.location.hostname === "localhost"){
    return "http://localhost:5000"
  }else if (window.location.hostname === "grc-distributor-fe-staging-4970338810bb.herokuapp.com"){
    return "https://grc-distributor-be-staging-fd56edd8662e.herokuapp.com"
  }else{
    return "https://grc-distributor-be-0814b1b10d58.herokuapp.com"
  }

}


export const options = () => {
    return {headers : {'Authorization': 'Bearer iloveshenyun&4cPu0PCUkN2C4u@xOs6%XUy2K@dqWuq',
          'Content-Type': 'application/json',
      }}
  }

