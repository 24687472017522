import * as React from 'react';
import MenuButton from '@mui/joy/MenuButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';


export default function OrderSelector({data}) {


  const [role,setRole] = React.useState(localStorage.getItem("role"));

  function handleChange(event){
    data(event.target.id);
  }

  return (
    <Dropdown>
      <MenuButton
        variant="plain"
        color="primary"
        endDecorator={<ArrowDropDown />}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Order by
      </MenuButton>
      <Menu sx={{ minWidth: 120 }}>
        <MenuItem onClick={handleChange} id="highestStockFirst">Highest Current Stock First (↑)</MenuItem>
        <MenuItem onClick={handleChange} id="lowestPriceFirst">Lowest Retail Price First (↓)</MenuItem>
        <MenuItem onClick={handleChange} id="highestPriceFirst">Highest Retail Price First (↑)</MenuItem>
        <MenuItem onClick={handleChange} id="lowestCommissionFirst">Lowest Commission First (↓)</MenuItem>
        <MenuItem onClick={handleChange} id="highestCommissionFirst">Highest Commission First (↑)</MenuItem>
        {role != "sales" ? <><MenuItem onClick={handleChange} id="lowestProfitFirst">Lowest Profit First (↓)</MenuItem>
        <MenuItem onClick={handleChange} id="highestProfitFirst">Highest Profit First (↑)</MenuItem></> : <></>}
        <MenuItem onClick={handleChange} id="lowestIdFirst">Lowest ID First (↓)</MenuItem>
        <MenuItem onClick={handleChange} id="highestIdFirst">Highest ID First (↑)</MenuItem>
        <MenuItem onClick={handleChange} id="ascendingName">Product Name (A to Z)</MenuItem>
        <MenuItem onClick={handleChange} id="descendingName">Product Name (Z to A)</MenuItem>
      </Menu>
    </Dropdown>
  );
}