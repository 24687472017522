import { useEffect, useState,useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import * as ProductsServices from '../../services/ProductsServices';
import * as UsersServices from '../../services/UsersServices';
import * as InvoicesServices from '../../services/InvoicesServices';
import * as CustomersServices from '../../services/CustomersServices';
import NavBar from '../../components/NavBar';
import DistributorCard from '../../components/DistributorCard';
import HeaderSection from '../../components/HeaderSection';
import Search from '../../components/Search';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import LinearProgress from '@mui/joy/LinearProgress';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import AspectRatio from '@mui/joy/AspectRatio';
import CardOverflow from '@mui/joy/CardOverflow';
import Grid from '@mui/joy/Grid';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Drawer from '@mui/joy/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import { setConsent } from 'firebase/analytics';
import { Navigate, useNavigate } from "react-router-dom";

var filteredCountry = "GLOBAL";
var filteredPrice = [0, 15000];
var bgimage = "https://s.hdnux.com/photos/01/35/57/64/24575256/5/rawImage.jpg"

export default function RentalDashboard() {

  const navigate = useNavigate();

  const [accessToken, setAccessToken]= useState(localStorage.getItem("accessToken"));
  const [username, setUsername]= useState(localStorage.getItem("name"));
  const [email, setEmail]= useState(localStorage.getItem("email"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [productsData, setProductsData] = useState([]);
  const [fullProductsData, setFullProductsData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [open, setOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [, forceRender] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [prevCart, setPrevCart] = useState(null);
  const [totalprice, setTotalprice] = useState(0);
  const [totalprofit, setTotalprofit] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [customerData, setCustomerData] = useState([]);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const inputRef = useRef(null);
  
  // const [searchVal, setSearchVal] = useState("");

  var searchVal = "";

  var paymenttype = "cash";

  const handleOnPaymentChange = (event) => {
    paymenttype=event.target.id;
  }

  // API CALL
  const add_one_invoice = async (details) => {
    try {
      setLoading(true);
      let res = await InvoicesServices.add_one_invoice(details);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully created an invoice")
       }
      setLoading(false);
    } catch (e) {

    }
  };
  

  //API
  const fetch_products = async () => {
    try {
      setLoading(true);
      if (role == "superadmin" ||role == "admin" ){
        let res = await ProductsServices.get_all_products();
        setProductsData(res.data);
        setFullProductsData(res.data);
      } else if (role == "distributor"){
        let res = await ProductsServices.get_distributor_products(role,uid);
        setProductsData(res.data);
        setFullProductsData(res.data);
      }
      else{
        setProductsData([]);
        setFullProductsData([]);
      }
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

   //API
   const fetch_product_by_id = async (id) => {
    try {
      setLoading(true);
      let res = await ProductsServices.get_product_by_id(id);
      setProductsData(res.data);
      setFullProductsData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  //API
  const update_cart_item = async (cartitem) => {
    try {
      let res = await UsersServices.update_user_product(cartitem);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully added to cart")
       }
    } catch (e) {
      setError(true);
    }
  };

   //API
   const delete_cart_items = async (cartitem) => {
    try {
      let res = await UsersServices.delete_all_user_product(uid,cartitem);
      if(res.data.success == true){
        fetch_user_cart();
        alert("You have successfully deleted cart item")
       }
    } catch (e) {
      setError(true);
    }
  };

  //API
  const fetch_user_cart = async () => {
    try {
      setLoading(true);
      if (role == "superadmin" ||role == "admin"||role == "distributor" ){ 
        let res = await UsersServices.get_one_user(uid);
        setPrevCart(res.data.b2bcart);
        let total = 0;
        let profit = 0;
        res.data.b2bcart != null && res.data.b2bcart != [] && res.data.b2bcart.length > 0 ? res.data.b2bcart.map ((product) =>
          {total += product?.quantity * product?.productdetails?.variantcompareprice*5
          profit += product?.quantity * (product?.productdetails?.retailprice - product?.productdetails?.variantcompareprice*5)})
        : total = 0;  
        setTotalprice(total);
        setTotalprofit(profit);
      }else{
        setPrevCart([]);
        setTotalprice(0);
        setTotalprofit(0);
      }
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  //API
  const fetch_user_by_id = async (id) => {
    try {
      setLoading(true);
      let res = await UsersServices.get_one_user(id);
      setCustomerData(res.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  };

  function handleDeleteCartItem(){
    const data = {
      id: uid,
      b2b: true
    }
    delete_cart_items(JSON.stringify(data, null, 2));
  }

  function handlePriceChange(data){
    filteredPrice=data;
  }

  async function handleCountryChange(data){
    filteredCountry = data;
  }

  function handleCardClick(data){
    const product = fullProductsData.find(product => product.id === data);
    setSelectedProduct(product);
    setOpen(true);
  }

  function handlePaymentButtonClick(){
    setPaymentModalOpen(true);
  }



  function handleOrderBy(data) {
    var sortedData = productsData;
    switch(data){

      case"lowestPriceFirst":
        sortedData = productsData.sort((a,b) => {
          return a.variantcompareprice - b.variantcompareprice;
        });
        break;
        
      case"highestPriceFirst":
        sortedData = productsData.sort((a,b) => {
          return b.variantcompareprice - a.variantcompareprice;
        });
        break;

      case"highestStockFirst":
        sortedData = productsData.sort((a,b) => {
          return b.currentstock - a.currentstock;
        });
        break;
      
      case"lowestCommissionFirst":
        sortedData = productsData.sort((a,b) => {
          return (((a.retailprice - a.variantcompareprice * 5) / (a.variantcompareprice * 5) * 100)>=60?
          (a.retailprice*20/100).toFixed(2):(a.retailprice*10/100).toFixed(2)) -
          (((b.retailprice - b.variantcompareprice * 5) / (b.variantcompareprice * 5) * 100)>=60?
          (b.retailprice*20/100).toFixed(2):(b.retailprice*10/100).toFixed(2))
        });
        break;

      case"highestCommissionFirst":
        sortedData = productsData.sort((a,b) => {
          return (((b.retailprice - b.variantcompareprice * 5) / (b.variantcompareprice * 5) * 100)>=60?
          (b.retailprice*20/100).toFixed(2):(b.retailprice*10/100).toFixed(2)) -
          (((a.retailprice - a.variantcompareprice * 5) / (a.variantcompareprice * 5) * 100)>=60?
          (a.retailprice*20/100).toFixed(2):(a.retailprice*10/100).toFixed(2))
        });
        break;

      case"lowestProfitFirst":
        sortedData = productsData.sort((a,b) => {
          return ((a.retailprice - a.variantcompareprice * 5) / (a.variantcompareprice * 5))
        });
        break;

      case"highestProfitFirst":
        sortedData = productsData.sort((a,b) => {
          return ((b.retailprice - b.variantcompareprice * 5) / (b.variantcompareprice * 5))
        });
        break;

      case"lowestIdFirst":
        sortedData = productsData.sort((a,b) => {
          const idA = a.id.toUpperCase(); // ignore upper and lowercase
          const idB = b.id.toUpperCase(); // ignore upper and lowercase
          if (idA < idB) {
            return -1;
          }
          if (idA > idB) {
            return 1;
          }

          // ids must be equal
          return 0;
        });
        break;

      case"highestIdFirst":
      sortedData = productsData.sort((a,b) => {
        const idA = a.id.toUpperCase(); // ignore upper and lowercase
        const idB = b.id.toUpperCase(); // ignore upper and lowercase
        if (idA > idB) {
          return -1;
        }
        if (idA < idB) {
          return 1;
        }

        // ids must be equal
        return 0;
      });
      break;  

      case"ascendingName":
      sortedData = productsData.sort((a,b) => {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;  
      });
      break; 

      case"descendingName":
      sortedData = productsData.sort((a,b) => {
        const nameA = a.productname.toUpperCase(); // ignore upper and lowercase
        const nameB = b.productname.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        // names must be equal
        return 0;  
      });
      break; 
    }
  
    setProductsData(sortedData);
    ProductsCardList();
    forceRender((prev) => !prev);
  }

  const handleSearchChange = (event) => {
    searchVal=event.target.value;
    // const sortedData = productsData.filter((product)=>product.productname.toLowerCase().includes(searchVal.toLowerCase()));
    // setProductsData(sortedData);
    // if (searchVal === "") { setProductsData(fullProductsData); }
    // const filterBySearch = productsData.filter((item) => {
    //     if (item.toLowerCase()
    //         .includes(searchVal.toLowerCase())) { return item; }
    // })
    // setProductsData(filterBySearch);
    // forceRender((prev) => !prev);

  }

  const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}

// merge(['a', 'b', 'c'], ['c', 'x', 'd']);
// // => ['a', 'b', 'c', 'x', 'd']

// merge([{id: 1}, {id: 2}], [{id: 2}, {id: 3}], (a, b) => a.id === b.id);
// // [{id: 1}, {id: 2}, {id: 3}]

  function handleSearchClick() {
    if (searchVal === "") { setProductsData(fullProductsData); return;}
    const [min, max] = filteredPrice;
    const country = filteredCountry;
    let bigarray = [];
    let splitedSearchVal = [];
    if (country != "GLOBAL") {
      splitedSearchVal = searchVal.toLowerCase().replace(/[^a-zA-Z-0-9 ]/g, "").split(" ");
      bigarray = splitedSearchVal.map( (val) =>
      fullProductsData.filter((product)=> product.productname.toLowerCase().includes(val)&&product.retailprice>=min && product.retailprice<=max && product.country.includes(country)));
    }else{
      splitedSearchVal = searchVal.toLowerCase().replace(/[^a-zA-Z-0-9 ]/g, "").split(" ");
      bigarray = splitedSearchVal.map( (val) =>
      fullProductsData.filter((product)=> product.productname.toLowerCase().includes(val)&&product.retailprice>=min && product.retailprice<=max ));
    }
    
    if (splitedSearchVal.length == 1){const [w] = bigarray;setProductsData(w);}
    if (splitedSearchVal.length >= 2){
      const [y, z] = bigarray;
      var count = 0;
      var finalarray = [];
      while (bigarray.length > 0) {
        if (count == 0){
          const [y, z] = bigarray;
          const mergedfirsttwo = merge(y, z, (a, b) => a.id === b.id);
          finalarray = mergedfirsttwo;
          const chunk = bigarray.splice(0,2);
        }
        if(count >= 1){
          const [x] = bigarray;
          const keepmerging = merge(finalarray, x, (a, b) => a.id === b.id);
          finalarray = keepmerging;
          const chunk = bigarray.splice(0,1);
        }
        count+=1;
        }
        setProductsData(finalarray);
      }
    
    
    }

    function CheckoutMobile () {
      return (<>{prevCart != [] && prevCart != null ? <Stack spacing={2} sx={{ overflow: 'auto' }}>
      <Card orientation="horizontal" variant="outlined" sx={{ borderRadius:0 }}>
      {/* <CardOverflow
        variant="soft"
        color="primary"
        sx={{
          px: 0.2,
          writingMode: 'vertical-rl',
          justifyContent: 'center',
          fontSize: 'xs',
          fontWeight: 'xl',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        Checkout
      </CardOverflow> */}
      <Grid
  container
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
<Typography fontWeight="md">
          Checkout Items (Total: RM{totalprice.toFixed(2)}) 
        </Typography>
        <Typography fontWeight="sm">
        Estimated profit before duty tax & shipping: RM{totalprofit.toFixed(2)}</Typography>
        <Grid
  container
  direction="horizontal"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
        <Button fontWeight="md" color="success" onClick={handlePaymentButtonClick}>
          Checkout Now
        </Button>
        <Button fontWeight="md" variant="outlined" color="danger" onClick={handleDeleteCartItem}>
        <DeleteRoundedIcon/> Reset Cart
        </Button>
        </Grid>
{prevCart.map ( (product) => <Card orientation="vertical" variant="plain">
      <CardOverflow>
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={product?.productdetails?.productimages}
            srcSet={product?.productdetails?.productimages}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
          {product?.productdetails?.productname}
        </Typography>
        <Typography level="body-sm">RM{(parseFloat(product?.productdetails?.variantcompareprice)*5).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.totalprice)).toFixed(2)}</b></Typography>
      </CardContent>
      </Card>)}
      </Grid>
    </Card>
    </Stack>:<></>}</>);
    }

    function CheckoutLaptop () {
      return (<>{prevCart != [] && prevCart != null ? <Stack spacing={2} sx={{ overflow: 'auto' }}>
      <Card orientation="horizontal" variant="outlined" sx={{ width: 480, borderRadius:0 }}>
      {/* <CardOverflow
        variant="soft"
        color="primary"
        sx={{
          px: 0.2,
          writingMode: 'vertical-rl',
          justifyContent: 'center',
          fontSize: 'xs',
          fontWeight: 'xl',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderRight: '1px solid',
          borderColor: 'divider',
        }}
      >
        Checkout
      </CardOverflow> */}
      <Grid
  container
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
<Typography fontWeight="md">
          Checkout Items (Total Price: RM{totalprice.toFixed(2)})
        </Typography>
        <Typography fontWeight="sm">
        Estimated profit before duty tax & shipping: RM{totalprofit.toFixed(2)}</Typography>
        <Grid
  container
  direction="horizontal"
  justifyContent="flex-start"
  alignItems="flex-start"
  gap={2}
>
        <Button fontWeight="md" color="success" onClick={handlePaymentButtonClick}>
          Checkout Now
        </Button>
        <Button fontWeight="md" variant="outlined" color="danger" onClick={handleDeleteCartItem}>
        <DeleteRoundedIcon/> Reset Cart
        </Button>
        </Grid>
{prevCart.map ( (product) => <Card orientation="horizontal" variant="plain" sx={{ width: 460 }}>
      <CardOverflow>
        <AspectRatio ratio="1" sx={{ width: 90 }}>
          <img
            src={product?.productdetails?.productimages}
            srcSet={product?.productdetails?.productimages}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography fontWeight="md" textColor="success.plainColor">
          {product?.productdetails?.productname}
        </Typography>
        <Typography level="body-sm">RM{(parseFloat(product?.productdetails?.variantcompareprice)*5).toFixed(2)} x {product?.quantity} = <b>RM{(parseFloat(product?.totalprice)).toFixed(2)}</b></Typography>
      </CardContent>
      </Card>)}
      </Grid>
    </Card>
    </Stack>:<></>}</>);
    }


  function ProductsCardList() {
    return (<>{ productsData ? productsData?.map(product => (
      <DistributorCard
      key={product?.id}
      title={product?.productname}
      preorder={product?.preorder}
      image={product?.productimages}
      country={product?.country}
      id={product?.id}
      retailprice={product?.retailprice}
      variantcompareprice={product?.variantcompareprice}
      currentstock={product?.currentstock}
      samplestock={product?.samplestock}
      onClick={handleCardClick}
    />
    )):<></>}</>  );
  }

  const TextInput = () => {
    return (
        <Input
        placeholder="Search"
        // value={'Melbourne'}
        startDecorator={<SearchRoundedIcon />}
        aria-label="Search"
        onChange={handleSearchChange}
      />
    );
  }

  const ProductModal = () =>{
    return (
      <Modal open={open} style = {{
        overflow:'scroll'
      }}
      onClose={()=>setOpen(false)} id='b2bproductmodal'>
        <ModalDialog>
        <DialogTitle>Add New Item to Cart</DialogTitle>
          <DialogContent>{selectedProduct?.productname}</DialogContent>
          <DialogContent>RM{(parseFloat(selectedProduct?.variantcompareprice)*5).toFixed(2)} each</DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formElements = event.currentTarget.elements;
              const cart = prevCart != [] && prevCart != null?  [...prevCart, {
                    id: selectedProduct?.id,
                    productdetails: selectedProduct,
                    totalprice: parseInt(formElements.quantity.value)*parseFloat(selectedProduct?.variantcompareprice)*5,
                    quantity: parseInt(formElements.quantity.value),
                    ordertype: "preorder",
                    deliverystatus: "pending",
                    remarks: formElements.remarks.value
                }]: [{
                  id: selectedProduct?.id,
                  productdetails: selectedProduct,
                  totalprice: parseInt(formElements.quantity.value)*parseFloat(selectedProduct?.variantcompareprice)*5,
                  quantity: parseInt(formElements.quantity.value),
                  ordertype: "preorder",
                  deliverystatus: "pending",
                  remarks: formElements.remarks.value
              }];
                const data = {
                    id: uid,
                    cart : cart,
                    b2b: true
                  };
              update_cart_item(JSON.stringify(data, null, 2));
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Quantity*</FormLabel>
                <Input
                  type="number"
                  name="quantity"
                  defaultValue={selectedProduct?.MOQ}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: selectedProduct?.MOQ,
                      max: 9999,
                      step: 1,
                    },
                  }}
                  required
                />
              </FormControl>
              <FormControl>
                <FormLabel>{"Remarks (Optional)"}</FormLabel>
                <Input name="remarks" />
              </FormControl>
              <Button type="submit" onClick={onsubmit}>Submit</Button>
            </Stack>
          </form>
          <ModalClose />
        </ModalDialog>
      </Modal>
    );
  }

  const PaymentModal = () =>{
    return (
      <Modal open={paymentModalOpen} 
      style = {{
        overflow:'scroll'
      }}
      onClose={()=>setPaymentModalOpen(false)}>
        <ModalDialog>
        <DialogTitle>Create Order</DialogTitle>
          <DialogContent>Total Amount: RM{totalprice.toFixed(2)}
            {customerData?.type=="lifetime"? <div>GRC Lifetime Member Perks Discount: RM{0.05*totalprice.toFixed(2)}</div> : <></>}
            {customerData?.cashablecredit ?  <div>Cashable Credit: RM{customerData?.cashablecredit?.toFixed(2)}</div> : <></>}
            {customerData?.uncashablecredit ? <div>Uncashable Credit: RM{customerData?.uncashablecredit?.toFixed(2)}</div> : <></>}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              var discounttype = ""
              var finalprice = totalprice
              var discount = 0
              var cashablecredit = 0
              var uncashablecredit = 0
              if(paymenttype==""){
                paymenttype="cash"
              }
              if(customerData?.type=="lifetime"){
                discounttype = "lifetime"
                finalprice = totalprice * 0.95
                discount = totalprice - finalprice
              }
              if(customerData?.uncashablecredit){
                finalprice = finalprice - customerData?.uncashablecredit
                uncashablecredit += customerData?.uncashablecredit
              }
              if(customerData?.cashablecredit){
                finalprice = finalprice - customerData?.cashablecredit
                cashablecredit += customerData?.cashablecredit
              }
              const formElements = event.currentTarget.elements;
                const data = {
                    id: uid,
                    cashiername: username,
                    cashieremail: email,
                    paymenttype : paymenttype,
                    product: prevCart,
                    remarks: formElements.remarks.value,
                    totalprice: totalprice,
                    estimatedgrossprofit: totalprofit, 
                    discounttype: discounttype,
                    discount: discount,
                    finalprice: finalprice,
                    cashablecredit: cashablecredit,
                    uncashablecredit: uncashablecredit,
                    createdAt: Date.now(),
                    b2border: true,
                    payoutstatus: "pending",
                    paymentstatus: "pending" 
                  };
                  // alert(JSON.stringify(data, null, 2));
              add_one_invoice(JSON.stringify(data, null, 2));
              setPaymentModalOpen(false);
              setOpenDrawer(false);
              setCustomerData([]);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Preferred Payment Type*</FormLabel>
                <Select name="paymenttype" onChange={handleOnPaymentChange} defaultValue="cash" required>
                  <Option value="cash" id="cash">Cash</Option>
                  <Option value="card" id="card">Card</Option>
                  <Option value="ewallet" id="ewallet">E-Wallet</Option>
                  <Option value="banktransfer" id="banktransfer">Bank Transfer</Option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{"Remarks (Optional)"}</FormLabel>
                <Input name="remarks" />
              </FormControl>
              <Button type="submit" onClick={onsubmit}>Confirm Order</Button>
            </Stack>
          </form> </DialogContent>
          <ModalClose />
        </ModalDialog>
      </Modal>
    );
  }

  function Search(){
    return (<div>
      <Stack spacing={1} direction="row" sx={{ mb: 2 }}>
        <FormControl sx={{ flex: 1 }} >
          <TextInput/>
        </FormControl>
        <Button variant="solid" color="primary" id="searchButton" onClick={handleSearchClick}>
          Search
        </Button>
      </Stack>
      <Typography level="body-sm">{productsData.length} products in total</Typography>
    </div>);
  }

  useEffect(() => {
    fetch_products();
    fetch_user_cart();
    fetch_user_by_id(uid);
  },[])

  if (loading) {
    return (<CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <Stack
            sx={{
              backgroundColor: 'background.surface',
              px: { xs: 2, md: 4 },
              py: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <HeaderSection title="B2B Products" description="It's time to replenish your stocks to achieve more"/>
            <Search />
          </Stack>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                `url(${bgimage})`,
            }}
          />
          <LinearProgress/>
        </Box>
      </CssVarsProvider>
    );
    } else if (!loading && accessToken && !error && (role == "superadmin" || role == "admin" || role == "distributor")){

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
      <Box
        component="main"
        sx={{
          height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'background.surface',
            px: { xs: 2, md: 4 },
            py: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          {/* Checkout Mobile */}
      {prevCart != [] && prevCart != null ? <Card
            sx={{
              display: { xs: 'flex', md: 'none' },
              width: '100%',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                width: '100%',
              }}
            > 
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={6}>
                <Typography variant="subtitle2">
                  Selected products 
                </Typography>
                <Typography variant="subtitle2">
                  RM{totalprice.toFixed(2)} 
                </Typography>
                </Grid>
                <Grid xs={6} sx={{alignSelf: "stretch",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",}}>
                <Button
        variant="text"
        endIcon={<ExpandMoreRoundedIcon />}
        onClick={toggleDrawer(true)}
      >
        View details <ExpandMoreRoundedIcon />
      </Button></Grid></Grid>
              <div>
      <Drawer open={openDrawer} anchor="top" onClose={toggleDrawer(false)}>
      <Box sx={{ width: 'auto', px: 3, pb: 3, pt: 8 }} role="presentation">
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <CheckoutMobile />
    </Box>
      </Drawer>
    </div>
            </CardContent>
          </Card>:<></>}
          <HeaderSection title="B2B Products" description="It's time to replenish your stocks to achieve more"/>
          <ProductModal/>
          <PaymentModal/>
          <Search />
        </Stack>
        <Box          sx={{
            gridRow: 'span 3',
            display: { xs: 'none', md: 'flex' },
             backgroundColor: 'background.level1',
             backgroundSize: 'cover',
             backgroundImage:
               `url(${bgimage})`,
          }}>
          {/* {prevCart?.length >= 0 ? <Card variant="plain" orientation="horizontal" >
         <CardContent>
          {prevCart?.map((product)=>
          <>
          <Typography level="title-md">{product.id}</Typography>
        <Typography>{product.quantity}</Typography></>)}
        </CardContent>
      </Card> : <></>} */}
      <CheckoutLaptop/>
      </Box>
        <Stack spacing={2} sx={{ px: { xs: 2, md: 4 }, pt: 2, minHeight: 0 }}>
          <Filters orderBy={handleOrderBy} priceChange={handlePriceChange} countryChange={(data) => handleCountryChange(data)}/>
          <Stack spacing={2} sx={{ overflow: 'auto' }}>
          <ProductsCardList/>
          </Stack>
        </Stack>
        {/* <Pagination /> */}
      </Box>
    </CssVarsProvider>
  );
}else{
  return(
    <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <NavBar accessToken={accessToken} username={username} email={email} role={role}/>
        <Box
          component="main"
          sx={{
            height: 'calc(100vh - 55px)', // 55px is the height of the NavBar
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
            gridTemplateRows: 'auto 1fr auto',
          }}
        >
          <Stack
            sx={{
              backgroundColor: 'background.surface',
              px: { xs: 2, md: 4 },
              py: 2,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography>You are not authorised to view this page. Kindly sign in first.</Typography>
          </Stack>
          <Box
            sx={{
              gridRow: 'span 3',
              display: { xs: 'none', md: 'flex' },
              backgroundColor: 'background.level1',
              backgroundSize: 'cover',
              backgroundImage:
                `url(${bgimage})`,
            }}
          />
        </Box>
      </CssVarsProvider>
  );
}

}