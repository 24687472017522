export const calculateGrossProfit = (discounttype,productprice,distributorprice) => {
    
    var discount = 0
    var commission = 0
    
    if (discounttype=="lifetime"){
        discount = productprice*0.05;
    }

    if ((productprice-distributorprice)/(distributorprice)*100 >= 60){
        commission = productprice*0.2;
    }else{
        commission = productprice*0.1;
    }

    var grossprofit = productprice - discount - commission - distributorprice;

    return grossprofit.toFixed(2)
}

export const splitCommissionEqually = (b2b,productprice,distributorprice,quantity,salesperson)=> {

    var commission = 0

    if (b2b){
        if ((productprice-distributorprice)/(distributorprice)*100 >= 60){
            commission = distributorprice*quantity*0.05;
        }else{
            commission = distributorprice*quantity*0.01;
        }
    }else{
        if ((productprice-distributorprice)/(distributorprice)*100 >= 60){
            commission = productprice*quantity*0.2;
        }else{
            commission = productprice*quantity*0.1;
        }
    }

    var splitCommission = commission/salesperson;

    return splitCommission.toFixed(2)
}

export const calculateCommission = (b2b,productprice,distributorprice,quantity)=> {

    var commission = 0

    if (b2b){
        if ((productprice-distributorprice)/(distributorprice)*100 >= 60){
            commission = distributorprice*quantity*0.05;
        }else{
            commission = distributorprice*quantity*0.01;
        }
    }else{
        if ((productprice-distributorprice)/(distributorprice)*100 >= 60){
            commission = productprice*quantity*0.2;
        }else{
            commission = productprice*quantity*0.1;
        }
    }

    return commission.toFixed(2)
}

export const calculateTotalDuty = (selectedRow)=> {


    var total = 0;
    selectedRow?.product?.map((product) => total += product?.dutytax*product?.productdetails?.variantcompareprice*5*product?.quantity)


    return total.toFixed(2)
}

export const calculateTotalDelivery = (selectedRow)=> {


    var total = 0;
    selectedRow?.product?.map((product) => total += product?.deliverycost*product?.quantity)


    return total.toFixed(2)
}