import * as React from 'react';
import { Box, IconButton } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import DiamondIcon from '@mui/icons-material/Diamond';
import ColorSchemeToggle from './ColorSchemeToggle';
import Button from '@mui/joy/Button';
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import Link from '@mui/joy/Link';

export default function NavBar({username, email}) {

  const navigate = useNavigate();

  const [role, setRole] = React.useState(localStorage.getItem("role"));

  const signOutUser = () => {
    signOut(auth).then(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("uid");
      alert("Log Out Successfully");
      navigate("/");
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  };

  const signInUser = () => {
    navigate("/");
  }

  const IconButtonMenu = () => {
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
        >
          <MoreVert />
        </MenuButton>
        <Menu>
        {username != "" && username != null && (role=="distributor"||role=="superadmin" || role=="admin") ? 
        <MenuItem><Link href="products-distributor" color="neutral" underline="none">B2B Products</Link></MenuItem> : 
        <></>}

        {username != "" && username != null && (role=="distributor"||role=="superadmin" || role=="admin") ? 
        <MenuItem><Link href="stocks" color="neutral" underline="none">Goods Sold</Link></MenuItem> : 
        <></>}

        {username != "" && username != null && (role=="sales" || role=="distributor" || role=="admin") ? 
        <MenuItem><Link href="stocks-sales" color="neutral" underline="none">Sales Made</Link></MenuItem> : 
        <></>}

        {username != "" && username != null && (role=="superadmin" ) ? 
        <MenuItem><Link href="payout" color="neutral" underline="none">Payout</Link></MenuItem> : 
        <></>}

        {username != "" && username != null ? 
        <MenuItem><Link href="products-sales-card" underline="none" color="neutral">Products</Link></MenuItem> : 
        <></>}

        {username != "" && username != null ? 
        <MenuItem><Link href="invoice" underline="none" color="neutral">Invoices</Link></MenuItem> : 
        <></>}

        {username != "" && username != null ? 
        <MenuItem onClick={signOutUser}>Sign Out</MenuItem> : 
        <MenuItem onClick={signInUser}>Sign In</MenuItem>}
        </Menu>
      </Dropdown>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        top: 0,
        px: 1.5,
        py: 1,
        zIndex: 10000,
        backgroundColor: 'background.body',
        borderBottom: '1px solid',
        borderColor: 'divider',
        position: 'sticky',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <IconButtonMenu/>
        {/* <IconButton size="sm" variant="soft">
        
        </IconButton> */}
        <Typography component="h1" fontWeight="xl">
          B2B Global Renaissance Collections
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
        <Box
          sx={{
            gap: 1,
            alignItems: 'center',
            display: { xs: 'none', sm: 'flex' },
          }}
        >

          {username != "" && username != null && (role=="distributor"||role=="superadmin"||role=="admin") ? 
          <><Link underline="none" href="products-distributor"><Button variant="plain" color="neutral">B2B Products</Button></Link>
          <Link underline="none" href="stocks"><Button variant="plain" color="neutral">Goods Sold</Button></Link></>: 
          <></>}

          {username != "" && username != null && (role=="sales" || role=="distributor"||role=="admin") ? 
          <Link underline="none" href="stocks-sales"><Button variant="plain" color="neutral">Sales Made</Button></Link>: 
          <></>}

          {username != "" && username != null && (role=="superadmin") ? 
          <Link underline="none" href="payout"><Button variant="plain" color="neutral">Payout</Button></Link>: 
          <></>}

          {username != "" && username != null ? 
          <Link underline="none" href="products-sales-card"><Button variant="plain" color="neutral">Products</Button></Link> : 
          <></>}

          {username != "" && username != null ? 
          <Link underline="none" href="invoice"><Button variant="plain" color="neutral">Invoices</Button></Link> : 
          <></>}

          {username != "" && username != null ? 
          <Button variant="plain" color="neutral" onClick={signOutUser}>Sign Out</Button> : 
          <Button variant="plain" color="neutral" onClick={signInUser}>Sign In</Button>}
          
          <Avatar
            variant="outlined"
            size="sm"
            src=""
          />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">{username}</Typography>
            <Typography level="body-xs">{email}</Typography>
          </Box>
        </Box>
        <ColorSchemeToggle sx={{ alignSelf: 'center' }} />
      </Box>
    </Box>
  );
}
