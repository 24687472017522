import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "stocks"

const stocks_api = axios.create({
  baseURL: `${base_url()}/${services}`,
  timeout: 30000,
});

export const update_stock = async (stock_details) => {
  try {
    const response = await stocks_api.post(`/update-stock`, stock_details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deduct_stock = async (stock_details) => {
  try {
    const response = await stocks_api.post(`/deduct-stock`, stock_details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_all_products_sold = async () => {
  try {
    const response = await stocks_api.get(`/list/sold`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_all_products_sold_by_distributorid = async (id) => {
  try {
    const response = await stocks_api.get(`/list/sold?distributorid=${id}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_all_products_sold_by_salesid = async (id) => {
  try {
    const response = await stocks_api.get(`/list/sold?salesid=${id}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};