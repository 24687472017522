import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PrimarySearchAppBar } from '../../components/Header';
import { Copyright } from '../../components/Footer';
import Alert from '@mui/material/Alert';
import * as ProductsServices from '../../services/ProductsServices';
import InputAdornment from '@mui/material/InputAdornment';
import Container from '@mui/material/Container';

const defaultTheme = createTheme();

export default function SignInSide() {

    const [imagePreview, setImagePreview] = useState("https://source.unsplash.com/random?wallpapers");
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [success, setSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState('');
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const product = [{
      id: data.get('id'),
      productname: data.get('productname'),
      productimages: data.get('productimages'),
      sycprice: parseFloat(data.get('sycprice')),
      sycb2bprice: parseFloat(data.get('sycb2bprice')),
      variantcompareprice: parseFloat(data.get('variantcompareprice')),
      retailprice: parseFloat(data.get('retailprice')),
      barcode: data.get('barcode'),
      SKU: data.get('SKU'),
      createdAt: Date.now()
    }];
    console.log(product);
    add_product(product);
  };

  // API CALL
  const add_product = async (productDetails) => {
    try {
      let res = await ProductsServices.add_one_product(productDetails);
      if (res.data.success == true ){
        setAlert(false);
      setSuccessContent("Product has been successfully created.");
      setSuccess(true);
      } else{
        setSuccess(false);
        console.log(res.data);
      setAlertContent("Unknown error occurred. Kindly contact admin for assistance.");
      setAlert(true);
      }
    } catch (e) {
        setSuccess(false);
      setAlertContent(e);
      setAlert(true);
    }
  };

  const imageOnChange = (event) => {
    setImagePreview(event.target.value);
  };

  if ( accessToken && role == "superadmin"){

  return (
    <ThemeProvider theme={defaultTheme}>
            <PrimarySearchAppBar/>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            //backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundImage: `url(${imagePreview})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {alert ? <Alert severity='error'>{alertContent}</Alert> : <></> }
          {success ? <Alert severity='success'>{successContent}</Alert> : <></> }
            <Typography component="h1" variant="h5">
              Create Product
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>            
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="productimages"
                  name="productimages"
                    label="Image Link"
                    onChange={imageOnChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="id"
                  name="id"
                label="Product ID"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="productname"
                  name="productname"
                    label="Product Name"
                    multiline
                    maxRows={8}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="SKU"
                  name="SKU"
                    label="SKU"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="barcode"
                  name="barcode"
                label="Barcode"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="sycprice"
                  name="sycprice"
                    label="SYC Retail Price"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="sycb2bprice"
                  name="sycb2bprice"
                label="SYC Distributor Price"
                InputProps={{
                  startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="retailprice"
                  name="retailprice"
                    label="Malaysia Retail Price"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="variantcompareprice"
                  name="variantcompareprice"
                label="Malaysia Distributor Price"
                InputProps={{
                  startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                }}
                />
              </Grid>
            </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}else{
  return(
      <ThemeProvider theme={defaultTheme}>
          <PrimarySearchAppBar/>
      <Container component="main" sx={{p:2}}>
          <CssBaseline />
      <Paper sx={{ width: '100%'}}>
       <p> You are not authorised to view this page</p>
      </Paper>
      <Copyright sx={{ mt: 5 }} />
      </Container>
      </ThemeProvider>
  );
}
}