import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "invoices"

const invoices_api = axios.create({
  baseURL: `${base_url()}/${services}`,
  timeout: 30000,
});

export const get_all_invoices = async () => {
    try {
      const response = await invoices_api.get(`/list`, options());
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
};

export const get_all_invoices_by_cashier_id = async (cashier_id) => {
  try {
    const response = await invoices_api.get(`/list?cashierid=${cashier_id}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const get_one_invoice = async (id) => {
  try {
    const response = await invoices_api.get(`/list?id=${id}`, options());
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const add_one_invoice = async (details) => {
  try {
    const response = await invoices_api.post(`/add`, details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const update_salesperson = async (details) => {
  try {
    const response = await invoices_api.post(`/sales`, details , options());
    // console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};