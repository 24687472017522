import axios from "axios";
import {base_url, options} from "../axios_config";

const services = "payout"

const payout_api = axios.create({
    baseURL: `${base_url()}/${services}`,
    timeout: 30000,
});


export const add_one_payout = async (payout_item) => {
    try {
    //   const response = await payout_api.post(`/add`, payout_item , options());
    console.log(payout_item);
      return ({success: true});
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };