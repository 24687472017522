import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../../components/Footer';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import Alert from '@mui/material/Alert';
import * as UsersServices from '../../services/UsersServices';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [success, setSuccess] = useState(false);
  const [successContent, setSuccessContent] = useState('');
  
  // API CALL
  const add_one_user = async (userDetails) => {
    try {
      let res = await UsersServices.add_one_user(userDetails);
      console.log(res);
    } catch (e) {
      setSuccess(false);
      setAlertContent(e);
      setAlert(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
  .then((userCredential) => {
    // Signed up 
    const user = userCredential.user;
    add_one_user({
      id: user.uid,
      email: data.get('email'),
      active: false,
      firstname: data.get('firstName'),
      lastname: data.get('lastName'),
      role: "sales",
      createdAt: Date.now()
    })
    setAlert(false);
    setSuccessContent("Congratulations! You have successfully signed up. Kindly wait for our admin approval to access in no time!")
    setSuccess(true);

  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    if(errorCode == "auth/email-already-in-use"){
      setSuccess(false);
      setAlertContent("We're sorry, this email already exists. You may login with this email or reset your password.");
      setAlert(true);
    }else{
    setSuccess(false);
    setAlertContent("Sign up failed: " + errorMessage);
    setAlert(true);
    }
  });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {alert ? <Alert severity='error'>{alertContent}</Alert> : <></> }
          {success ? <Alert severity='success'>{successContent}</Alert> : <></> }
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Typography component="h3" variant="h6">
            B2B Global Renaissance Collections
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}