// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdUfrOdeayTvaKWJpZp9j57EdgUBXnQIE",
  authDomain: "grc-distributor.firebaseapp.com",
  projectId: "grc-distributor",
  storageBucket: "grc-distributor.appspot.com",
  messagingSenderId: "75828101559",
  appId: "1:75828101559:web:5f841a0e048ad6701ce832",
  measurementId: "G-XTKS20SRXR"
};

const firebaseConfig_staging = {
  apiKey: "AIzaSyBejGXaI39KEfvUTORxLZYporc7RWAmGWk",
  authDomain: "grc-staging.firebaseapp.com",
  projectId: "grc-staging",
  storageBucket: "grc-staging.appspot.com",
  messagingSenderId: "781785800805",
  appId: "1:781785800805:web:10470a232261d9be52268c",
  measurementId: "G-NK0Z9PHHY8"
};

var app;
var auth;

// Initialize Firebase
 //test
 if (window.location.hostname === "localhost"){
  app = initializeApp(firebaseConfig_staging);
  auth = getAuth(app);
  connectAuthEmulator(auth, "http://localhost:9099");
} else if (window.location.hostname === "grc-distributor-fe-staging-4970338810bb.herokuapp.com"){
  app = initializeApp(firebaseConfig_staging);
  auth = getAuth(app);
}else{
  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
}

const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service

// export const auth = getAuth(app);
// export default app;

export { auth };